<template>
	<div>
        <v-row no-gutters align="center" justify="center">
            <v-col cols="12" sm="12" md="8" lg="8" xl="8" xxl="5" class="px-0">
                <v-img contain class="cryptoImg" aspect-ratio="1" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/aad2e856-ebd1-4197-ccd4-5df177c4e300/public"></v-img>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4" xxl="5" class="d-flex flex-column px-0 c_inside r-p-x-4">
                <div class="home-36 marcellus-sc">
                    Untrading: Transform Your Crypto Trading Experience
                </div>
                <div class="home-16 lexend-deca text-primaryGrey mt-4">
                    Untrading's unCrypto platform revolutionizes how you interact with digital assets. Here's what you need to know:
                </div>
                <h4 class="h4 text-white03 mt-8 mb-4">
                    Buy and Sell unCryptos
                </h4>
                <div class="h5 mb-4 text-primaryGrey lexend-deca">
                    Trade wrapped versions of top ERC-20 tokens like BTC and ETH.
                </div>
                <h4 class="h4 mt-8 mb-4 text-white03">
                    Whitelisted Tokens
                </h4>
                <div class="h5 text-primaryGrey lexend-deca mb-4">
                    Currently, five carefully selected tokens are available for wrapping.
                </div>
                <h4 class="h4 mt-8 mb-4 text-white03">
                    Originator Control
                </h4>
                <div class="h5 text-primaryGrey lexend-deca mb-4">
                    Token originators set reward structures and generation cycles.
                </div>
                <h4 class="h4 mt-8 mb-4 text-white03">
                    Kingdom Governance
                </h4>
                <div class="h5 text-primaryGrey lexend-deca mb-4">
                    In the future, Kingdom Leaders will decide which assets to whitelist in their realms. Along with several customizations and extensions.
                </div>
                <h4 class="h4 mt-8 mb-4 text-white03">
                    Limitless Potential
                </h4>
                <div class="h5 text-primaryGrey lexend-deca">
                    From NFTs to stocks, bonds, and real-world assets - anything can be tokenized.
                </div>
            </v-col>
        </v-row>
	</div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        
    }
}
</script>
<style scoped>
</style>
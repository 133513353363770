<template>
	<div>
        <v-row no-gutters justify="start" class="r-m-t-16">
            <v-col cols="12" sm="6" md="6" xxl="6" class="pa-0">
                <v-img cover :aspect-ratio="1" src="https://kingdoms.untrading.org/media/posts/11/responsive/Golden-Crown-Alex-Style-2xl.webp"></v-img>
            </v-col>
            <v-col cols="12" sm="6" md="6" xxl="6" class="px-0 pl-16 r-p-l-0 p-p-l-8 m-t-16">
                <h4 class="h4 mb-4 text-white03 r-p-l-4">Tangible Community</h4>
                <div class="h5 text-primaryGrey lexend-deca r-p-l-4">Create a digital nation where your influence translates to real, lasting value. Shape the future of finance and governance in your own Network State.</div>
                <h4 class="h4 mt-16 mb-4 text-white03 r-p-l-4">From Follower to Citizen</h4>
                <div class="h5 text-primaryGrey lexend-deca r-p-l-4">Transform your social media followers into active citizens of your digital kingdom. Engage them in collaborative wealth creation and shared governance. </div>
                <h4 class="h4 mt-16 mb-4 text-white03 r-p-l-4">Your Community, Your Rules</h4>
                <div class="h5 text-primaryGrey lexend-deca r-p-l-4">Set custom rules for asset management and profit-sharing within your kingdom. Unlike traditional platforms, you control the rewards and governance structure.</div>
            </v-col>
        </v-row>
        <v-row no-gutters class="mt-100">
            <v-col cols="12" sm="6" md="6" xxl="6" class="pl-4 r-p-l-0 r-text-right px-0">
                <h4 class="h4 mb-4 text-white03 r-p-l-4">Lasting Rewards for Leaders</h4>
                <div class="h5 text-primaryGrey lexend-deca r-p-l-4">Benefit from Originators' Rewards and Future Rewards, ensuring you profit from your community's long-term success. Your influence becomes a sustainable, growing asset.</div>
                <h4 class="h4 mt-16 mb-4 text-white03 r-p-l-4">Collaborative Wealth Building</h4>
                <div class="h5 text-primaryGrey lexend-deca r-p-l-4">Work together with your community members to maximize returns and share in the prosperity. As your kingdom grows, so do the rewards for all participants.</div>
                <h4 class="h4 mt-16 mb-4 text-white03 r-p-l-4">Bridging Digital and Physical Realms</h4>
                <div class="h5 text-primaryGrey lexend-deca r-p-l-4">Start in the digital space and potentially expand into physical meetups or shared spaces. Build a true Network State that transcends traditional boundaries. </div>
                <div class="del-underline gradient-underline-hover text-primary h5 mt-16 r-p-l-4">
                    <router-link to="/kols" class="text-primary lexend-deca">
                        More <span class="mdi mdi-arrow-right-thin"></span>
                    </router-link>
                </div>
            </v-col>
            <v-col cols="12" sm="6" md="6" xxl="6" class="px-0 pl-16 r-p-l-0 order-first-mobile p-p-l-8 m-b-16">
                <v-img cover :aspect-ratio="1" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/098efd76-4aba-4974-dca9-ba52fe97ea00/public"></v-img>
            </v-col>
        </v-row>
	</div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>
/* Default order for all items */
.order-first-mobile {
    order: 2;
  }
  
  /* On mobile devices, make this item first */
  @media only screen and (max-width: 600px) {
    .order-first-mobile {
      order: -1;
    }
  }
</style>
import axios from '@/config/api.request'
import qs from 'qs'
// qs.stringfy() 将对象序列化成URL的形式
// https://www.cnblogs.com/edwardwzw/p/11694903.html

export default {
    // 查询加密货币NFR统计数据
    getNfrStat(blockchain) {
        return axios.request({
            url: '/crypto/nfr/stat',
            method: 'get',
            params: { 
                blockchain: blockchain
            }
        })
    },
    // 分页查询活跃的加密货币NFR列表
    getActiveNfrsByPage(page, size, sortBy, blockchain, wrappedCurrency, quoteCurrency) {
        return axios.request({
            url: '/crypto/nfr/nfrs/active',
            method: 'get',
            params: { page, size, sortBy, blockchain, wrappedCurrency, quoteCurrency }
        })
    },
    // 分页查询完成的加密货币NFR列表
    getCompletedNfrsByPage(page, size, sortBy, blockchain, wrappedCurrency, quoteCurrency) {
        return axios.request({
            url: '/crypto/nfr/nfrs/completed',
            method: 'get',
            params: { page, size, sortBy, blockchain, wrappedCurrency, quoteCurrency }
        })
    },
    // 分页查询封装的加密货币NFR列表
    getWrappedNfrsByPage(page, size, sortBy, blockchain, wrappedCurrency, quoteCurrency) {
        return axios.request({
            url: '/crypto/nfr/nfrs/wrapped',
            method: 'get',
            params: { page, size, sortBy, blockchain, wrappedCurrency, quoteCurrency }
        })
    },
    // 分页查询解封的加密货币NFR列表
    getUnwrappedNfrsByPage(page, size, sortBy, blockchain, wrappedCurrency, quoteCurrency) {
        return axios.request({
            url: '/crypto/nfr/nfrs/unwrapped',
            method: 'get',
            params: { page, size, sortBy, blockchain, wrappedCurrency, quoteCurrency }
        })
    },
    // 根据加密货币主键查询NFR信息
    getNfrByCryptoId(cryptoId) {
        return axios.request({
            url: '/crypto/nfr/nfr',
            method: 'get',
            params: {
                cryptoId: cryptoId
            }
        })
    },
    // 根据加密货币主键和用户主键查询OR信息
    getORPayment(cryptoId) {
        return axios.request({
            url: '/crypto/nfr/payment/ors',
            method: 'get',
            params: {
                cryptoId: cryptoId
            }
        })
    },
    // 根据加密货币主键和用户主键查询FR信息
    getFRPayment(cryptoId, userId) {
        return axios.request({
            url: '/crypto/nfr/payment/frs',
            method: 'get',
            params: {
                cryptoId: cryptoId,
                userId: userId
            }
        })
    },
    // 根据加密货币主键和用户主键查询nFR支付记录
    getNFRPayout(cryptoId, userId) {
        return axios.request({
            url: '/crypto/nfr/payout/nfrs',
            method: 'get',
            params: {
                cryptoId: cryptoId,
                userId: userId
            }
        })
    },
    // 根据加密货币主键和用户主键查询nFR支付记录是否无效
    isInvalidNFRPayout(cryptoId, userId) {
        return axios.request({
            url: '/crypto/nfr/payout/nfrs/invalid',
            method: 'get',
            params: {
                cryptoId: cryptoId,
                userId: userId
            }
        })
    },
    // 根据参数估算 NFR
    estimateNfr(data) {
        return axios.request({
            url: '/crypto/nfr/estimator',
            method: 'post',
            data: data
        })
    },
    // 根据参数计算 NFR
    calculateNfr(data) {
        return axios.request({
            url: '/crypto/nfr/calculator',
            method: 'post',
            data: data
        })
    },
}
<template>
    <div class="gradient-top-blue-white">
        <v-navigation-drawer v-model="drawer" temporary  location="left" fixed width="380" color="black" style="z-index:3000">
            <v-list bg-color="grey02" nav density="compact">
                <v-list-item>
                    <router-link to="/">
                        <div class="logo-sub mt-3">
                            <logo type="header" color="light" responsive="mobile"></logo>
                            <!-- class="img-top"  -->
                        </div>
                    </router-link>
                    <template v-slot:append>
                        <v-icon aria-label="close" @click="drawer = !drawer" icon="mdi:mdi-close"></v-icon>
                    </template>
                </v-list-item>
            </v-list>
            <v-list density="compact" nav class="body-p" bg-color="grey02">
                <v-divider></v-divider>
                <div v-if="$config.env != 'prod'">
                    <a href="https://untrading.org/" class="del-underline text-primaryGrey font-weight-bold" target="_blank">
                        <v-list-item class="gradient-underline-hover">
                            <a class="text-primaryGrey gradient-text-hover">Open App</a>
                        </v-list-item>
                    </a>
                    <v-divider></v-divider>
                </div>
                <v-list-group value="Products">
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" class="text-primaryGrey font-weight-bold">Products</v-list-item>
                    </template>
                    <div v-for="(menu, index) in productMenus" :key="index">
                        <v-divider></v-divider>
                        <router-link v-if="menu.linkType == 'to'" :to="menu.link" :aria-label="menu.title" class="del-underline text-primaryGrey font-weight-bold">
                            <v-list-item class="gradient-underline-hover">
                                <a class="text-primaryGrey gradient-text-hover">{{ menu.title }}</a>
                            </v-list-item>
                        </router-link>
                        <a v-if="menu.linkType == 'a'" :href="menu.link" :aria-label="menu.title" class="del-underline text-primaryGrey font-weight-bold"  target="_blank">
                            <v-list-item class="gradient-underline-hover">
                                <a class="text-primaryGrey gradient-text-hover">{{ menu.title }}</a>
                            </v-list-item>
                        </a>
                    </div>
                </v-list-group>
                <v-divider></v-divider>
                <v-list-group value="About">
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" class="text-primaryGrey font-weight-bold">About</v-list-item>
                    </template>
                    <div v-for="(menu, index) in aboutMenus" :key="index">
                        <v-divider></v-divider>
                        <router-link v-if="menu.linkType == 'to'" :to="menu.link" :aria-label="menu.title" class="del-underline text-primaryGrey font-weight-bold">
                            <v-list-item class="gradient-underline-hover">
                                <a class="text-primaryGrey gradient-text-hover">{{ menu.title }}</a>
                            </v-list-item>
                        </router-link>
                        <a v-if="menu.linkType == 'a'" :href="menu.link" :aria-label="menu.title" class="del-underline text-primaryGrey font-weight-bold"  target="_blank">
                            <v-list-item class="gradient-underline-hover">
                                <a class="text-primaryGrey gradient-text-hover">{{ menu.title }}</a>
                            </v-list-item>
                        </a>
                    </div>
                </v-list-group>
                <v-divider></v-divider>
                <router-link to="/kols" aria-label="KOLs" class="del-underline text-primaryGrey font-weight-bold">
                    <v-list-item class="gradient-underline-hover">
                        <a class="text-primaryGrey gradient-text-hover">KOLs</a>
                    </v-list-item>
                </router-link>
                <v-divider></v-divider>
                <a href="https://kingdoms.untrading.org" aria-label="Kingdoms" class="del-underline text-primaryGrey font-weight-bold"  target="_blank">
                    <v-list-item class="gradient-underline-hover">
                        <a class="text-primaryGrey gradient-text-hover">Kingdoms</a>
                    </v-list-item>
                </a>
                <v-divider></v-divider>
                <v-list-group value="Docs">
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" class="text-primaryGrey font-weight-bold">Docs</v-list-item>
                    </template>
                    <div v-for="(menu, index) in docMenus" :key="index">
                        <v-divider></v-divider>
                        <router-link v-if="menu.linkType == 'to'" :to="menu.link" :aria-label="menu.title" class="del-underline text-primaryGrey font-weight-bold">
                            <v-list-item class="gradient-underline-hover">
                                <a class="text-primaryGrey gradient-text-hover">{{ menu.title }}</a>
                            </v-list-item>
                        </router-link>
                        <a v-if="menu.linkType == 'a'" :href="menu.link" :aria-label="menu.title" class="del-underline text-primaryGrey font-weight-bold"  target="_blank">
                            <v-list-item class="gradient-underline-hover">
                                <a class="text-primaryGrey gradient-text-hover">{{ menu.title }}</a>
                            </v-list-item>
                        </a>
                    </div>
                </v-list-group>
                <v-divider></v-divider>
                <v-list-group value="Developers">
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" class="text-primaryGrey font-weight-bold">Developers</v-list-item>
                    </template>
                    <div v-for="(menu, index) in developerMenus" :key="index">
                        <v-divider></v-divider>
                        <router-link v-if="menu.linkType == 'to'" :to="menu.link" :aria-label="menu.title" class="del-underline text-primaryGrey font-weight-bold">
                            <v-list-item class="gradient-underline-hover">
                                <a class="text-primaryGrey gradient-text-hover">{{ menu.title }}</a>
                            </v-list-item>
                        </router-link>
                        <a v-if="menu.linkType == 'a'" :href="menu.link" :aria-label="menu.title" class="del-underline text-primaryGrey font-weight-bold"  target="_blank">
                            <v-list-item class="gradient-underline-hover">
                                <a class="text-primaryGrey gradient-text-hover">{{ menu.title }}</a>
                            </v-list-item>
                        </a>
                    </div>
                </v-list-group>
                <v-divider></v-divider>
                <v-list-group value="APPs">
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" class="text-primaryGrey font-weight-bold">APPs</v-list-item>
                    </template>
                    <div v-for="(menu, index) in appMenus" :key="index">
                        <v-divider></v-divider>
                        <router-link v-if="menu.linkType == 'to'" :to="menu.link" :aria-label="menu.title" class="del-underline text-primaryGrey font-weight-bold">
                            <v-list-item class="gradient-underline-hover">
                                <a class="text-primaryGrey gradient-text-hover">{{ menu.title }}</a>
                            </v-list-item>
                        </router-link>
                        <a v-if="menu.linkType == 'a'" :href="menu.link" :aria-label="menu.title" class="del-underline text-primaryGrey font-weight-bold"  target="_blank">
                            <v-list-item class="gradient-underline-hover">
                                <a class="text-primaryGrey gradient-text-hover">{{ menu.title }}</a>
                            </v-list-item>
                        </a>
                    </div>
                </v-list-group>
            </v-list>
            <!-- 媒体 -->
            <v-list density="compact" nav class="pl-4">
                <!-- Twitter -->
                <a href="https://x.com/untradingOrg" target="_blank" class="del-underline mr-2" aria-label="X (Twitter)">
                    <v-icon class="icon" size="24" aria-label="X (Twitter)" color="text-primaryGrey">icon-x</v-icon>
                </a>
                <!-- Telegram -->
                <a href="https://t.me/untrading" target="_blank" class="del-underline mr-2" aria-label="Telegram">
                    <v-icon class="icon" size="24" aria-label="Telegram" color="text-primaryGrey">icon-te</v-icon>
                </a>
                <!-- Discord -->
                <a href="https://discord.gg/D5rZdhzVWh" target="_blank" class="del-underline mr-2" aria-label="Discord">
                    <v-icon class="icon" ize="24" aria-label="Discord" scolor="text-primaryGrey">icon-dis</v-icon>
                </a>
                <!-- GitHub -->
                <a href="https://github.com/untrading" target="_blank" class="del-underline mr-2" aria-label="GitHub">
                    <v-icon class="icon" size="24" aria-label="GitHub" color="text-primaryGrey">icon-github</v-icon>
                </a>
                <!-- spotify -->
                <a href="https://podcasters.spotify.com/pod/show/untrading" target="_blank" class="del-underline mr-2" aria-label="spotify">
                    <v-icon class="icon" size="24" aria-label="spotify" color="text-primaryGrey">icon-spotify</v-icon>
                </a>
                <!-- medium -->
                <a href="https://untrading.medium.com/" target="_blank" class="del-underline mr-2" aria-label="spotify">
                    <v-icon class="icon" size="24" aria-label="spotify" color="text-primaryGrey">icon-medium</v-icon>
                </a>
                <!-- linkedin -->
                <a href="https://www.linkedin.com/company/untrading/" target="_blank" class="del-underline mr-2" aria-label="spotify">
                    <v-icon class="icon" size="24" aria-label="spotify" color="text-primaryGrey">icon-linkedin</v-icon>
                </a>
                 <!-- youtube -->
                 <a href="https://www.youtube.com/@untrading" target="_blank" class="del-underline mr-2" aria-label="spotify">
                    <v-icon class="icon" size="24" aria-label="spotify" color="text-primaryGrey">icon-youtube</v-icon>
                </a>
            </v-list>
            <template v-slot:append>
                <v-divider></v-divider>
                <div class="py-4 pl-4">
                    <theme show-text></theme>
                </div>
            </template>
        </v-navigation-drawer>
        <v-app-bar fixed elevation="0" color="homeBg">
            <v-row justify="space-between" align="center" class="mx-3">
                <div>
                    <router-link to="/" class="pointer ml-2">
                        <div class="logo  mt-2">
                            <logo type="header" color="light" responsive="mobile"></logo>
                        </div>
                    </router-link>
                </div>
                <div>
                    <v-icon icon="mdi:mdi-menu" size="30" color="primary" @click.stop="drawer = !drawer"></v-icon>
                </div>
            </v-row>
        </v-app-bar>
        <!-- 固定 Discord -->
        <a href="https://discord.gg/D5rZdhzVWh" target="_blank" class="del-underline">
            <v-btn position="fixed" location="bottom right" class="mb-13" aria-label="discord" icon variant="text" size="large" style="z-index: 1;">
                <v-icon class="icon" aria-label="discord" color="primary" size="32">icon-dis</v-icon>
            </v-btn>
        </a>
        <!-- 消息条 -->
        <Snackbar></Snackbar>
    </div>
</template>
<script>
import Logo from '@/components/common/Logo';
import Theme from '@/components/common/Theme';
import Snackbar from '@/components/common/Snackbar';
import { mapGetters } from "vuex";
export default {
    data(){
        return {
            search: null,
            drawer: null,
            currentUrl: window.location.href,
            nftMenus: [
                { title: 'Explore', icon: 'mdi:mdi-compass', to: '/market' },
                { title: 'Curated', icon: 'mdi:mdi-star', to: '/sets' },
                { title: 'Collections', icon: 'mdi:mdi-image-filter-black-white', to: '/collections' }
            ],
            // 搜索图表路径
            searchIconPaths: ['/uncryptos'],
            // 搜索文本路径
            searchTextPaths: ['/uncryptos/search'],
        }
    },
    components: { Logo, Theme, Snackbar },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['darkTheme', 'token']),
        // 产品菜单
        productMenus() {
            let menus = [
                { title: 'unCryptos', iconType: 'icon', icon: null, linkType: 'to', link: '/uncryptos' },
                { title: 'unNFTs', iconType: 'icon', icon: null, linkType: 'to', link: '/unnfts' },
                { title: 'Testnet', iconType: 'icon', icon: null, linkType: 'a', link: 'https://testnet.untrading.org' },
            ];
            return menus;
        },
        // 关于菜单
        aboutMenus() {
            let menus = [
                { title: 'Connection', iconType: 'icon', icon: null, linkType: 'to', link: '/the-network-state-connection' },
                { title: 'FAQs', iconType: 'icon', icon: null, linkType: 'a', link: 'https://docs.untrading.org/faqs' },
                { title: 'Terms of Service', iconType: 'icon', icon: null, linkType: 'a', link: 'https://docs.untrading.org/terms-of-service' },
                { title: 'Privacy Policy', iconType: 'icon', icon: null, linkType: 'a', link: 'https://docs.untrading.org/privacy-policy' },
                { title: 'Legal', iconType: 'icon', icon: null, linkType: 'a', link: 'https://docs.untrading.org/risks' },
                { title: '5173 Foundation', iconType: 'icon', icon: null, linkType: 'a', link: 'https://5173.foundation' },
                { title: 'UN Token', iconType: 'icon', icon: null, linkType: 'to', link: '/un' },
            ];
            return menus;
        },
        // 文档菜单
        docMenus() {
            let menus = [
                { title: 'ERC-5173', iconType: 'icon', icon: null, linkType: 'a', link: 'https://eips.ethereum.org/EIPS/eip-5173' },
                { title: 'Whitepaper', iconType: 'icon', icon: null, linkType: 'a', link: 'https://docs.untrading.org/whitepaper' },
                { title: 'Tutorials', iconType: 'icon', icon: null, linkType: 'a', link: 'https://docs.untrading.org/tutorials' },
            ];
            return menus;
        },
        // 开发者菜单
        developerMenus() {
            let menus = [
                { title: 'Documentation', iconType: 'icon', icon: null, linkType: 'a', link: 'https://docs.untrading.org' },
                { title: 'GitHub', iconType: 'icon', icon: null, linkType: 'a', link: 'https://github.com/untrading' },
            ];
            return menus;
        },
        // App 菜单
        appMenus() {
            let menus = [
                { title: 'unCryptos', iconType: 'icon', icon: null, linkType: 'to', link: '/uncryptos' },
                { title: 'unNFTs', iconType: 'icon', icon: null, linkType: 'to', link: '/unnfts' },
            ];
            return menus;
        },
    },
    watch:{
        $route: {
            handler(n, o) {
                this.currentUrl = window.location.href;
            },
            immediate: true
        },
    },
    methods: {
        
    }
}
</script>
<style scoped>

</style>
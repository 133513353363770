<template>
    <div class="gradient-top-blue-white">
        <v-navigation-drawer v-model="drawer" temporary  location="left" fixed width="380" color="black" style="z-index:3000">
            <v-list bg-color="grey02" nav density="compact">
                <v-list-item>
                    <router-link to="/">
                        <div class="logo-sub mt-3">
                            <logo type="header" color="light" responsive="mobile"></logo>
                            <!-- class="img-top"  -->
                        </div>
                    </router-link>
                    <template v-slot:append>
                        <v-icon aria-label="close" @click="drawer = !drawer" icon="mdi:mdi-close"></v-icon>
                    </template>
                </v-list-item>
            </v-list>
            <v-list density="compact" nav class="body-p" bg-color="grey02">
                <v-divider></v-divider>
                <a href="/" class="del-underline text-primaryGrey font-weight-bold">
                    <v-list-item class="gradient-underline-hover">
                       <a class="text-primaryGrey gradient-text-hover">Home</a>
                    </v-list-item>
                </a>
                <v-divider></v-divider>
                <div>
                    <a href="https://kingdoms.untrading.org/" aria-label="Kingdoms" class="del-underline text-primaryGrey font-weight-bold" target="_blank">
                        <v-list-item class="gradient-underline-hover">
                            <a class="text-primaryGrey gradient-text-hover">Kingdoms</a>
                        </v-list-item>
                    </a>
                </div>
                <v-divider></v-divider>
                <v-list-group value="unCryptos">
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" title="unCryptos" class="text-primaryGrey font-weight-bold"></v-list-item>
                    </template>
                    <div v-for="(menu, index) in cryptoMenus" :key="index">
                        <v-divider></v-divider>
                        <router-link :to="menu.to" :aria-label="menu.title" class="del-underline text-primaryGrey font-weight-bold">
                            <v-list-item class="gradient-underline-hover">
                                <a class="text-primaryGrey gradient-text-hover">{{ menu.title }}</a>
                            </v-list-item>
                        </router-link>
                    </div>
                </v-list-group>
                <v-divider></v-divider>
                <v-list-group value="unNFTs">
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" title="unNFTs" class="text-primaryGrey font-weight-bold"></v-list-item>
                    </template>
                    <v-divider></v-divider>
                    <router-link to="/unnfts" class="del-underline text-primaryGrey font-weight-bold">
                        <v-list-item class="gradient-underline-hover">
                        <a class="text-primaryGrey gradient-text-hover">Discover</a>
                        </v-list-item>
                    </router-link>
                    <v-divider></v-divider>
                    <router-link to="/market" class="del-underline text-primaryGrey font-weight-bold">
                        <v-list-item class="gradient-underline-hover">
                        <a class="text-primaryGrey gradient-text-hover">Markets</a>
                        </v-list-item>
                    </router-link>
                    <v-divider></v-divider>
                    <router-link to="/sets" class="del-underline text-primaryGrey font-weight-bold">
                        <v-list-item class="gradient-underline-hover">
                        <a class="text-primaryGrey gradient-text-hover">Curated Sets</a>
                        </v-list-item>
                    </router-link>
                    <v-divider></v-divider>
                    <router-link to="/collections" class="del-underline text-primaryGrey font-weight-bold">
                        <v-list-item class="gradient-underline-hover">
                        <a class="text-primaryGrey gradient-text-hover">Collections</a>
                        </v-list-item>
                    </router-link>
                </v-list-group>               
                <v-divider></v-divider>
                <div>
                    <router-link to="/un" class="del-underline text-primaryGrey font-weight-bold">
                        <v-list-item class="gradient-underline-hover">
                            <a class="text-primaryGrey gradient-text-hover">UN Token</a>
                        </v-list-item>
                    </router-link>
                </div>
                <v-divider></v-divider>
               <v-list-group value="Tutorials">
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" title="Tutorials" class="text-primaryGrey font-weight-bold"></v-list-item>
                    </template>
                    <v-divider></v-divider>
                    <a href="https://docs.untrading.org/tutorials/how-to-buy-and-sell-uncryptos-on-untrading.org-testnet" aria-label="unCryptos" class="del-underline text-primaryGrey font-weight-bold" target="_blank">
                        <v-list-item class="gradient-underline-hover">
                        <a class="text-primaryGrey gradient-text-hover">unCryptos</a>
                        </v-list-item>
                    </a>
                    <v-divider></v-divider>
                    <a href="https://docs.untrading.org/tutorials/minting-an-unnft-and-list-it-for-sell-on-untrading" aria-label="unNFTs" class="del-underline text-primaryGrey font-weight-bold" target="_blank">
                        <v-list-item class="gradient-underline-hover">
                        <a class="text-primaryGrey gradient-text-hover">unNFTs</a>
                        </v-list-item>
                    </a>
                </v-list-group>
                <v-divider></v-divider>
                <v-list-group value="Docs">
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" title="Docs" class="text-primaryGrey font-weight-bold"></v-list-item>
                    </template>
                    <v-divider></v-divider>
                    <a href="https://docs.untrading.org" class="del-underline text-primaryGrey font-weight-bold" target="_blank">
                        <v-list-item class="gradient-underline-hover">
                        <a class="text-primaryGrey gradient-text-hover">Introduction</a>
                        </v-list-item>
                    </a>
                    <v-divider></v-divider>
                    <a href="https://eips.ethereum.org/EIPS/eip-5173" class="del-underline text-primaryGrey font-weight-bold" target="_blank">
                        <v-list-item class="gradient-underline-hover">
                        <a class="text-primaryGrey gradient-text-hover">ERC-5173</a>
                        </v-list-item>
                    </a>
                    <v-divider></v-divider>
                    <a href="https://docs.untrading.org/whitepaper" class="del-underline text-primaryGrey font-weight-bold" target="_blank">
                        <v-list-item class="gradient-underline-hover">
                        <a class="text-primaryGrey gradient-text-hover">Whitepaper</a>
                        </v-list-item>
                    </a>
                </v-list-group>
                <v-divider></v-divider>
                <a href="https://docs.untrading.org/faqs/general" class="del-underline text-primaryGrey font-weight-bold" target="_blank">
                    <v-list-item class="gradient-underline-hover">
                       <a class="text-primaryGrey gradient-text-hover">FAQ</a>
                    </v-list-item>
                </a>
                <v-divider></v-divider>
                <a href="https://untrading.medium.com/" class="del-underline text-primaryGrey font-weight-bold" target="_blank">
                    <v-list-item class="gradient-underline-hover">
                       <a class="text-primaryGrey gradient-text-hover">Blog</a>
                    </v-list-item>
                </a>
                <v-divider></v-divider>
                <v-list-group value="Legal">
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" title="Legal" class="text-primaryGrey font-weight-bold"></v-list-item>
                    </template>
                    <v-divider></v-divider>
                    <a href="https://docs.untrading.org/risks" aria-label="Risks" class="del-underline text-primaryGrey font-weight-bold" target="_blank">
                        <v-list-item class="gradient-underline-hover">
                        <a class="text-primaryGrey gradient-text-hover">Risks</a>
                        </v-list-item>
                    </a>
                    <v-divider></v-divider>
                    <a href="https://docs.untrading.org/community-guidelines" aria-label="Community guidelines" class="del-underline text-primaryGrey font-weight-bold" target="_blank">
                        <v-list-item class="gradient-underline-hover">
                        <a class="text-primaryGrey gradient-text-hover">Community guidelines</a>
                        </v-list-item>
                    </a>
                    <v-divider></v-divider>
                    <a href="https://docs.untrading.org/terms-of-service" aria-label="Terms of service" class="del-underline text-primaryGrey font-weight-bold" target="_blank">
                        <v-list-item class="gradient-underline-hover">
                        <a class="text-primaryGrey gradient-text-hover">Terms of service</a>
                        </v-list-item>
                    </a>
                    <v-divider></v-divider>
                    <a href="https://docs.untrading.org/privacy-policy" aria-label="Privacy police" class="del-underline text-primaryGrey font-weight-bold" target="_blank">
                        <v-list-item class="gradient-underline-hover">
                        <a class="text-primaryGrey gradient-text-hover">Privacy police</a>
                        </v-list-item>
                    </a>
                </v-list-group>
                <!-- app -->
                <div v-if="$config.env == 'prod'">
                    <v-divider></v-divider>
                    <a href="https://testnet.untrading.org/" class="del-underline text-primaryGrey font-weight-bold" target="_blank">
                        <v-list-item class="gradient-underline-hover">
                        <a class="text-primaryGrey gradient-text-hover">Testnet</a>
                        </v-list-item>
                    </a>
                </div>
                <div v-if="$config.env != 'prod'">
                    <v-divider></v-divider>
                    <!-- Ethereum -->
                    <v-list-group value="Ethereum">
                        <template v-slot:activator="{ props }">
                            <v-list-item v-bind="props" title="Ethereum" class="text-primaryGrey font-weight-bold"></v-list-item>
                        </template>
                        <v-divider></v-divider>
                        <a href="https://sepolia.etherscan.io" class="del-underline text-primaryGrey font-weight-bold" target="_blank">
                            <v-list-item class="gradient-underline-hover">
                                <a class="text-primaryGrey gradient-text-hover">Ethereum Sepolia</a>
                            </v-list-item>
                        </a>
                        <v-divider></v-divider>
                        <a href="https://sepoliafaucet.com" class="del-underline text-primaryGrey font-weight-bold" target="_blank">
                            <v-list-item class="gradient-underline-hover">
                                <a class="text-primaryGrey gradient-text-hover">Ethereum Sepolia Faucet</a>
                            </v-list-item>
                        </a>
                        <v-divider></v-divider>
                        <a href="https://goerli.etherscan.io" class="del-underline text-primaryGrey font-weight-bold" target="_blank">
                            <v-list-item class="gradient-underline-hover">
                                <a class="text-primaryGrey gradient-text-hover">Ethereum Goerli</a>
                            </v-list-item>
                        </a>
                        <v-divider></v-divider>
                        <a href="https://goerli-faucet.pk910.de" class="del-underline text-primaryGrey font-weight-bold" target="_blank">
                            <v-list-item class="gradient-underline-hover">
                                <a class="text-primaryGrey gradient-text-hover">Ethereum Goerli Faucet</a>
                            </v-list-item>
                        </a>
                    </v-list-group>
                    <v-divider></v-divider>
                    <!-- Polygon -->
                    <v-list-group value="Polygon">
                        <template v-slot:activator="{ props }">
                            <v-list-item v-bind="props" title="Polygon" class="text-primaryGrey font-weight-bold"></v-list-item>
                        </template>
                        <v-divider></v-divider>
                        <a href="https://mumbai.polygonscan.com" class="del-underline text-primaryGrey font-weight-bold" target="_blank">
                            <v-list-item class="gradient-underline-hover">
                                <a class="text-primaryGrey gradient-text-hover">Polygon Mumbai</a>
                            </v-list-item>
                        </a>
                        <v-divider></v-divider>
                        <a href="https://mumbaifaucet.com" class="del-underline text-primaryGrey font-weight-bold" target="_blank">
                            <v-list-item class="gradient-underline-hover">
                                <a class="text-primaryGrey gradient-text-hover">Polygon Faucet</a>
                            </v-list-item>
                        </a>
                    </v-list-group>
                </div>
                <v-divider></v-divider>
            </v-list>
            <!-- 媒体 -->
            <v-list density="compact" nav class="pl-4">
                <!-- Twitter -->
                <a href="https://x.com/untradingOrg" target="_blank" class="del-underline mr-2" aria-label="X (Twitter)">
                    <v-icon class="icon" size="24" aria-label="X (Twitter)" color="text-primaryGrey">icon-x</v-icon>
                </a>
                <!-- Telegram -->
                <a href="https://t.me/untrading" target="_blank" class="del-underline mr-2" aria-label="Telegram">
                    <v-icon class="icon" size="24" aria-label="Telegram" color="text-primaryGrey">icon-te</v-icon>
                </a>
                <!-- Discord -->
                <a href="https://discord.gg/D5rZdhzVWh" target="_blank" class="del-underline mr-2" aria-label="Discord">
                    <v-icon class="icon" size="24" aria-label="Discord" color="text-primaryGrey">icon-dis</v-icon>
                </a>
                <!-- GitHub -->
                <a href="https://github.com/untrading" target="_blank" class="del-underline mr-2" aria-label="GitHub">
                    <v-icon class="icon" size="24" aria-label="GitHub" color="text-primaryGrey">icon-github</v-icon>
                </a>
                <!-- spotify -->
                <a href="https://podcasters.spotify.com/pod/show/untrading" target="_blank" class="del-underline mr-2" aria-label="spotify">
                    <v-icon class="icon" size="24" aria-label="spotify" color="text-primaryGrey">icon-spotify</v-icon>
                </a>
                 <!-- medium -->
                 <a href="https://untrading.medium.com/" target="_blank" class="del-underline mr-2" aria-label="spotify">
                    <v-icon class="icon" size="24" aria-label="spotify" color="text-primaryGrey">icon-medium</v-icon>
                </a>
                <!-- linkedin -->
                <a href="https://www.linkedin.com/company/untrading/" target="_blank" class="del-underline mr-2" aria-label="spotify">
                    <v-icon class="icon" size="24" aria-label="spotify" color="text-primaryGrey">icon-linkedin</v-icon>
                </a>
                 <!-- youtube -->
                 <a href="https://www.youtube.com/@untrading" target="_blank" class="del-underline mr-2" aria-label="spotify">
                    <v-icon class="icon" size="24" aria-label="spotify" color="text-primaryGrey">icon-youtube</v-icon>
                </a>
            </v-list>
            <template v-slot:append>
                <v-divider></v-divider>
                <div class="py-4 pl-4">
                    <theme show-text></theme>
                </div>
            </template>
        </v-navigation-drawer>
        <v-app-bar fixed elevation="0" color="black">
            <v-row justify="space-between" align="center" class="mx-3">
                <div>
                    <span v-if="$route.path == '/uncryptos/search'">
                        <v-icon icon="mdi mdi-chevron-left" color="primary" size="x-large" @click="this.$router.go(-1)"></v-icon>
                    </span>
                    <span v-else>
                        <v-icon icon="mdi:mdi-menu" size="30" color="primary" @click.stop="drawer = !drawer"></v-icon>
                    </span>
                </div>
                <div>
                    <span v-if="searchIconPaths.indexOf($route.path) >= 0">
                        <router-link to="/uncryptos/search" class="del-underline">
                            <v-icon icon="mdi mdi-magnify" color="primary" size="x-large"></v-icon>
                        </router-link>
                    </span>
                    <span v-else-if="searchTextPaths.indexOf($route.path) >= 0">
                        <span class="body-h4 text-primaryGrey">Search</span>
                    </span>
                    <span v-else>
                        <router-link to="/" class="pointer ml-2">
                            <div class="logo">
                                <logo type="header" color="light" responsive="mobile"></logo>
                            </div>
                        </router-link>
                    </span>
                </div>
                <div class="d-flex">
                    <Installation class="mr-2"></Installation>
                    <span class="icon" v-if="token == null">
                        <router-link :to="'/connectwallet?redirectUrl=' + currentUrl" class="del-underline">
                            <v-icon aria-label="wallet" class="icon mb-2" color="primary"  size="30">mdi mdi-wallet-outline</v-icon>
                        </router-link>
                    </span>
                    <Avatar></Avatar>
                </div>
            </v-row>
        </v-app-bar>
        
        <!-- 固定 Discord -->
        <a href="https://discord.gg/D5rZdhzVWh" target="_blank" class="del-underline">
            <v-btn position="fixed" location="bottom right" class="mb-96" aria-label="discord" icon variant="text" size="large" style="z-index: 1;">
                <v-icon class="icon" aria-label="discord" color="primary" size="32">icon-dis</v-icon>
            </v-btn>
        </a>
        <!--固定举报作弊图标 -->
        <v-btn position="fixed" location="bottom right" class="mb-13" aria-label="alert" icon variant="text" size="large" style="z-index: 999;" @click="reportCheatingDialog = true">
            <v-icon class="icon" color="primaryGrey" size="32">icon-alert</v-icon>
        </v-btn>
        <!-- 举报作弊弹窗 -->
        <ReportCheating :show="reportCheatingDialog" @watchReportCheatingDialog="watchReportCheatingDialog"></ReportCheating>
        <!-- 同步钱包网络 -->
        <!-- <SyncBlockchain></SyncBlockchain> -->
        <!-- 测试网弹窗 -->
        <!-- <TestnetDialog></TestnetDialog> -->
        <!-- 消息条 -->
        <Snackbar></Snackbar>
    </div>
</template>
<script>
import Logo from '@/components/common/Logo';
import Theme from '@/components/common/Theme';
import Notifications from '@/components/common/Notifications';
import Installation from '@/components/common/Installation';
import Avatar from '@/components/user/mobile/Avatar';
import ReportCheating from '@/components/common/ReportCheating';
import SyncBlockchain from '@/components/common/SyncBlockchain';
import TestnetDialog from '@/components/common/TestnetDialog';
import Snackbar from '@/components/common/Snackbar';
import { mapGetters } from "vuex";
export default {
    data(){
        return {
            search: null,
            drawer: null,
            // 举报作弊弹窗
            reportCheatingDialog: false,
            currentUrl: window.location.href,
            nftMenus: [
                { title: 'Explore', icon: 'mdi:mdi-compass', to: '/market' },
                { title: 'Curated', icon: 'mdi:mdi-star', to: '/sets' },
                { title: 'Collections', icon: 'mdi:mdi-image-filter-black-white', to: '/collections' }
            ],
            // 搜索图表路径
            searchIconPaths: ['/uncryptos'],
            // 搜索文本路径
            searchTextPaths: ['/uncryptos/search'],
        }
    },
    components: { Logo, Notifications, Installation, Avatar, ReportCheating, SyncBlockchain, TestnetDialog, Theme, Snackbar },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['darkTheme', 'token', 'cryptoProducts']),
        // Crypto 菜单
        cryptoMenus() { 
            let menus = [
                { title: 'Markets', iconType: 'icon', icon: 'mdi:mdi-trending-up', to: '/uncryptos' },
            ];
            this.cryptoProducts.forEach(product => {
                let menu = {
                    title: product.currency,
                    iconType: 'image',
                    icon: product.icon,
                    to: `/uncryptos?product=${product.currency}`
                }
                menus.push(menu);
            });
            return menus;
        }
    },
    watch:{
        $route: {
            handler(n, o) {
                this.currentUrl = window.location.href;
            },
            immediate: true
        },
    },
    methods: {
        // 监听举报作弊弹窗
        watchReportCheatingDialog(show) {
            this.reportCheatingDialog = show;
        }
    }
}
</script>
<style scoped>

</style>
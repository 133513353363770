<template>
	<div >
        <!--  -->
        <v-row no-gutters align="center" justify="center">
            <v-col cols="12" sm="12" md="8" lg="7" xl="7" xxl="9" class="d-flex justify-center align-center">
                <div class="d-flex flex-column">
                    <div class="hero-16 lexend-deca text-primaryGrey gradient-underline-hover">
                        Powered by 
                            <a href="https://erc-5173.org" target="_blank" class="del-underline text-homeHover pointer">
                                <span class="gradient-text-hover">ERC-5173</span>
                            </a> 
                        Standard
                    </div>
                    <div class="home-80 mt-8 marcellus-sc">
                        The Network DeFi
                    </div>
                    <v-card color="transparent" rounded="0" elevation="0" max-width="1300" min-width="280" class="home-24 lexend-deca mt-8 mb-16 text-primaryGrey gradient-underline-hover">
                        Untrading is the economic foundation for 
                        <a href="https://thenetworkstate.com/" target="_blank" class="del-underline text-homeHover pointer">
                            <span class="gradient-text-hover">Network States</span>
                        </a>. 
                        Build, grow, and maintain connections — even after selling assets. Built by and for: 
                        <v-typical class="blink" :steps="['you', 1000, 'traders', 500, 'leaders', 1000,'builders', 1000,'communities', 1000,'kingdoms', 1000, 'network nations', 1000]" :loop="Infinity" :wrapper="'home-24'">
                        </v-typical>
                    </v-card>
                </div>
            </v-col>   
        </v-row>    
        <v-row no-gutters align="center" justify="center">
            <v-col cols="12" sm="12" md="8" lg="7" xl="7" xxl="9" class="d-flex justify-center align-center">
                <v-row no-gutters justify="space-between">
                    <v-cols cols="12" sm="12" md="12" class="px-0">
                        <router-link to="/uncryptos" class="del-underline gradient-left-red-purple-170 mr-16 r-m-r-4">
                            <v-btn rounded="0" elevation="2" color="primary" class="text-none text-white home-18 lexend-deca font-weight-bold" width="170" height="48" aria-label="unCrypto App">unCrypto App</v-btn>
                        </router-link>
                        <router-link to="/unnfts" class="del-underline gradient-left-red-purple-170">
                            <v-btn rounded="0" elevation="2" color="primary" class="text-none text-white home-18 r-r-m-t-4 lexend-deca font-weight-bold" width="170" height="48" aria-label="unNFT App">unNFT App</v-btn>
                        </router-link>
                    </v-cols>
                    <!-- <v-col cols="12" sm="12" md="4" class="px-0 d-flex justify-end align-end r-m-t-16">
                        <div class="d-flex r-m-r-0">
                            <div class="mr-8">
                                <a href="https://x.com/untradingOrg" target="_blank" class="del-underline">
                                    <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/d63c1182-e0bd-44cd-d5ad-7334f9b6b100/public"  aria-label="X (Twitter)" role="img" width="24" height="24"></v-img>
                                </a>
                            </div>
                            <div class="mr-8">
                                <a href="https://t.me/untrading" target="_blank" class="black--text del-underline" aria-label="Telegram">
                                    <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/88bd42d6-38b2-4775-ebdc-b5d28b927500/public" width="24" height="24"></v-img>
                                </a>
                            </div>
                            <div class="mr-8">
                                <a href="https://discord.gg/D5rZdhzVWh" target="_blank" class="black--text del-underline" aria-label="Discord">
                                    <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/ac803eba-9354-40bd-3de0-1c6ab1cc6d00/public" width="24" height="24"></v-img>
                                </a>
                            </div>
                            <div class="mr-8">
                                <a href="https://github.com/untrading" target="_blank" class="text-decoration-none" aria-label="GitHub">
                                    <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/48737c90-42b0-4069-a44a-e3a4616fdb00/public" width="24" height="24"></v-img>
                                </a>
                            </div>
                            <div>
                                <a href="https://podcasters.spotify.com/pod/show/untrading" target="_blank" class="text-decoration-none" aria-label="GitHub">
                                    <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/0032faef-692f-45ff-f4bd-35d85887d300/public" width="24" height="24"></v-img>
                                </a>
                            </div>
                        </div>
                    </v-col> -->
                </v-row>
            </v-col>
        </v-row> 
	</div>
</template>
<script>
//VTypical https://github.com/Turkyden/vue-typical
import VTypical from 'vue-typical';
export default {
    data(){
        return {

        }
    },
    components: { VTypical },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>
.typicalWrapper::after {
    content: "|";
    animation: blink 1s infinite step-start;
  }
  
  @keyframes blink {
    50% { opacity: 0; }
  }
  .blink{
    color: rgb(var(--v-theme-primary))!important;
  }
</style>
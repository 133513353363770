<template>
	<div>
        <!--  -->
        <v-row no-gutters align="center" justify="center">
            <v-col cols="12" sm="12" md="4" xl="4" xxl="5" class="d-flex flex-column px-0 r-m-t-16 n_inside r-p-x-0 r-text-right">
                <h4 class="h4 text-white03 mt-8 mb-4 r-p-x-4">
                    Mint your original NFTs
                </h4>
                <div class="h5 text-primaryGrey lexend-deca r-p-l-4">
                    As a revolutionary platform, Untrading combines a decentralized exchange (DEX) with an innovative NFT marketplace, compliant with the additional Future Rewards protocol.
                </div>
                <h4 class="h4 text-white03 mt-8 mb-4 r-p-l-4">
                    Or Wrap white-listed top NFT collections
                </h4>
                <div class="h5 text-primaryGrey lexend-deca r-p-l-4">
                    Unlock the full potential of your digital collectibles while maintaining their security. With Untrading, your NFTs work harder for you.
                </div>
            </v-col>
            <v-col cols="12" sm="12" md="8" xl="8" xxl="5" class="px-0 p-l-4 order-first-mobile">
                <v-img contain  class="nftImg" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/6afb6bff-4dbe-438b-075a-adc6506ac500/public" v-show="this.darkTheme == 1" ></v-img>
                <v-img contain  class="nftImg" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/78871603-b6be-4aee-83e6-5df2a7924300/public" v-show="this.darkTheme == 0"></v-img>
            </v-col>
        </v-row>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(["darkTheme"])
    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>
/* Default order for all items */
.order-first-mobile {
    order: 2;
  }
  
  /* On mobile devices, make this item first */
  @media only screen and (max-width: 600px) {
    .order-first-mobile {
      order: -1;
    }
  }
  @media only screen and (max-width: 1024px) {
    .order-first-mobile {
      order: -1;
    }
  }
</style>
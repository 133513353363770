<template>
	<div>
        <v-container class="h-100vh bg-homeBg">
            <div class="d-flex align-center justify-center home-85vh">
                <Hero></Hero>
            </div>
        </v-container>
        <v-container class="pa-0 bg-black">
            <Calculator class="calculator"></Calculator>
        </v-container>
        <v-container class="pa-0 r-p-x-0">
            <!-- Revolution -->
            <v-row no-gutters class="py-300 bg-homeBg">
                <v-col cols="12">
                    <Revolution></Revolution>
                </v-col>
            </v-row>
            <!-- Kingdom -->
            <v-row no-gutters justify="center" align="end" class="py-300 bg-black">
                <v-col cols="12" sm="12" md="8" xl="8">
                    <div class="d-flex flex-column mb-16 r-p-x-4">
                        <div class="home-36 marcellus-sc">Build Your Kingdom on Untrading</div>
                        <div class="home-24 marcellus-sc text-primaryGrey font-weight-bold mt-4">Become a King, or Queen, serve your realm, bridge the on-chain Silk Road.</div>
                        <div class="home-16 lexend-deca text-primaryGrey mt-4">Kingdoms on Untrading allow you to utilize our technologies to build your collaborative decentralized hedge pools for your community.</div> 
                    </div>
                    <Kingdom></Kingdom>
                </v-col>
            </v-row>

            <!-- Market -->
            <v-row no-gutters class="bg-homeBg py-200"  justify="center" align="center">
                <v-col cols="12" sm="12" md="12" xl="12" xxl="12" class="px-0">
                    <CryptoScreenshot></CryptoScreenshot>
                    <v-row no-gutters justify="start" align="center">
                        <v-col cols="12" sm="12" lg="3" xl="3" xxl="3" class="position-relative marketTop">
                            <Market></Market>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <!-- CryptoScreenshot -->
            <v-row no-gutters class="py-300 bg-black" justify="center" align="center">
                <v-col cols="12" sm="12" md="12" xl="12" xxl="12" class="px-0">
                    <NftScreenshot></NftScreenshot>
                </v-col>
            </v-row>

            <!-- Data -->
            <v-row no-gutters class="py-300 bg-homeBg" justify="center" align="center">
               <v-col cols="12" sm="12" md="8">
                    <Data></Data>
               </v-col>
            </v-row>

            <v-row no-gutters class="py-300 bg-black" justify="center" align="center">
                <v-col cols="12" md="7" sm="12" class="px-0 r-p-l-4 home-20 text-primaryGrey lexend-deca">
                    Self-Funding and Control: With over $2 million dollars invested with skin in the game, we are committed to reduce the conflicts in strategic direction or pressure for short-term gains at the expense of long-term value creation
                </v-col>
            </v-row>

            <!-- FAQ -->
            <v-row no-gutters class="py-300 bg-homeBg" justify="center" align="center">
                <v-col cols="12" sm="12" md="8" class="px-0 r-p-x-4">
                    <div class="home-48 marcellus-sc">FAQs</div>
                </v-col>
                <v-col cols="12" sm="12" md="8" class="px-0 r-p-x-4 mt-8">
                    <Faq></Faq>
                    <div class="del-underline gradient-underline-hover text-primary mt-5 r-p-l-0">
                        <a href="https://docs.untrading.org/faqs" target="_blank" class="h5 text-primary lexend-deca">
                            More <span class="mdi mdi-arrow-right-thin"></span>
                        </a>
                    </div>
                </v-col>
            </v-row>

            <!-- News -->
            <v-row no-gutters class="py-300 bg-black" justify="center" align="center">
                <v-col cols="12" sm="12" md="8" class="px-0 r-p-x-4">
                    <div class="home-48 marcellus-sc mb-16">In the News</div>
                    <Meida></Meida>
                </v-col>
            </v-row>
            
            <v-row no-gutters justify="center" class="bg-black pb-250">
                <v-col cols="12" sm="12" md="10" class="px-0">
                    <BuiltOn></BuiltOn>
                </v-col>
            </v-row>

            <v-divider></v-divider>
            <!-- Footer -->
            <v-row no-gutters justify="center" class="bg-homeBg">
                <v-col cols="12" sm="12" md="8" class="px-0">
                    <Footer></Footer>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row no-gutters justify="center" class="bg-homeBg" v-if="responsive == 'pc'">
                <v-col cols="12" sm="12" md="8" class="gradient-underline-hover h5 d-flex justify-end align-center my-2">
                    <Theme show-text size="22"></Theme>
                </v-col>
            </v-row>
        </v-container> 
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Calculator from './components/Calculator.vue';
import Hero from './components/Hero.vue';
import CryptoScreenshot from './components/CryptoScreenshot.vue';
import Data from './components/Data.vue';
import Faq from './components/Faq.vue';
import Revolution from './components/Revolution.vue';
import Footer from './components/Footer.vue';
import Kingdom from './components/Kingdom.vue';
import NftScreenshot from './components//NftScreenshot.vue';
import Market from './components//Market.vue';
import Meida from './components//Meida.vue';
import BuiltOn from './components/BuiltOn.vue';
import Theme from '@/components/common/Theme';
export default {
    data(){
        return {
            scroll: 0,
        }
    },
    components: { Calculator, Hero, CryptoScreenshot, Data, Faq, Kingdom, NftScreenshot, Market, Meida, Footer, Revolution, BuiltOn, Theme },
    created(){
      
    },
    mounted(){

    },
    computed: {
            ...mapGetters(["darkTheme",'responsive'])
        },
    watch:{

    },
    methods: {
    
    }
}
</script>
<style scoped>

</style>